import React from 'react';
import { Link } from 'react-router-dom';
import newgenLabsLogo from '../images/newgenlabs-G-logo.png';

import '../styles/Landing.scss';

const Landing = () => {
    return (
        <div className="Landing">
            <img src={newgenLabsLogo} className="Landing-logo" alt="NewGen Labs Company Logo" />
            <h1>WELCOME TO NEWGEN LABS</h1>
            <p>
            We are working on some very special things, stay tuned!
            </p>
            <p>
            In the meantime, head on over to <a href="https://playforge.gg" target="_blank" rel="noreferrer" >playforge.gg</a> and start earning in the arena today!
            </p>

            <Link to="/preview" className="standard-btn">Preview Drop</Link>
        </div>
    );
};

export default Landing;
