import React from 'react';
import { Link } from 'react-router-dom';

//Images
import GLOGO from '../images/newgenlabs-logo.png';

//Styles
import '../styles/Navbar.scss';

const Navbar = ({login, logout, loggedIn, user, balance}) => {

    return (
        <div className="navbar">
            <div className="navbar-left">
                <Link to="/"><img className="navbar-logo" src={GLOGO} alt="NEWGEN LABS logo" /></Link>
            </div>
            <div className="navbar-right">
                <a
                    className="navigationButton discordButton"
                    target="_blank"
                    rel="noreferrer"
                    href="https://newgenlabs.notion.site/Esports-Ascendance-Capsule-067dad6032c6499e83ce4a0164cbdd67"
                >
                    Drop Details
                </a>
                <a
                    className="navigationButton"
                    target="_blank"
                    rel="noreferrer"
                    href="https://account.playforge.gg/"
                >
                    Play Forge
                </a>
                <a
                    className="navigationButton"
                    target="_blank"
                    rel="noreferrer"
                    href="https://discord.gg/nftsgen"
                >
                    Discord
                </a>
                <div className="navbar-right-account">
                    <div className="navbar-right-account-info">
                        {loggedIn && <p>Wallet ID: {user}</p>}
                        {loggedIn && <p>Balance: {balance && balance}</p>}
                    </div>
                    {loggedIn && (
                        <div className="standard-btn" onClick={() => logout()}>
                            <p>Disconnect</p>
                        </div>)}
                         {/* : (
                        <div className="standard-btn" onClick={() => login()}>
                            <p>Connect</p>
                        </div>
                    )} */}
                </div>

            </div>
        </div>
    )
}

export default Navbar;