import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';

// Pages
import Landing from './pages/Landing';
import Drop from './pages/Drop';

//Components
import Navbar from './components/Navbar';

// Styles
import './styles/App.scss';

const getBalance = async (rpc, walletID) => {
  const balance = await rpc.get_currency_balance('eosio.token', walletID, 'WAX');
  return balance;
}

const App = () => {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const [user, setUser] = useState("");
  const [balance, setBalance] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false); // tracks if there is a wallet connected

  const ual = useContext(UALContext);

  useEffect(() => {
    
    const fetchData = () => {
      if (ual.activeUser && ual.activeAuthenticator) {

        let rpc = ual.activeUser.rpc;
        let accountName = ual.activeUser.accountName;

        if(!rpc) {
          if(ual.activeUser.wax && ual.activeUser.wax.rpc) {
            rpc = ual.activeUser.wax.rpc;
          }
        }

        if (accountName && rpc) {
          setUser(accountName);

          getBalance(rpc, accountName)
            .then(balance => {
              if(balance.length) {
                const waxBalance = Number(balance[0].split(" ")[0]);
                setBalance(`${waxBalance} WAX`);
              } else {
                setBalance("0 WAX");
              }
            });

          setLoggedIn(true); // set loggedIn true, if already seeing wallet data
        }
      }
    };

    fetchData();
  }, [ual.activeUser, ual.activeAuthenticator]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({width: window.innerWidth, height: window.innerHeight});
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  });

  const login = () => {
    if(!ual.activeUser && !ual.activeAuthenticator) {
      ual.showModal();
    }
  }

  const logout = () => {
    ual.logout();
    setLoggedIn(false); // set loggedIn false, if logging out
    setUser(null);
    setBalance(null);
  }

  return (
    <div className="App">
      
      <Navbar
        login={login}
        logout={logout}
        loggedIn={loggedIn}
        user={user}
        balance={balance}
        windowSize={windowSize}
      />

      <Routes>
        <Route
          path="/"
          element={
            <Landing />
          }
        />

        <Route
          path="/preview"
          element={
            <Drop
              loggedIn={loggedIn}
              login={login}
              windowSize={windowSize}
            />
          }
        />
      </Routes>
      <div className="App-overlay"></div>
    </div>
  );
};

export default App;
